.crypto-select-wrapper {
  .coin-symbol {
    position: absolute;
    left: 17%;
    top: 22%;
    align-content: center;
    font-size: 20px;
    font-weight: 700;
    margin-left: -4px;
    text-align: center;
    min-width: 48px;
  }
}

.crypto-menu {
  margin-top: 14px;
}