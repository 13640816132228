h1 {
    color: rgb(0, 0, 0);
    font-size: 30px;
}

.signup-form-signin{
    float: left;
}

.signup-btn{
    float: right;
    width: 30%;
}