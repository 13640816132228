.menu-layout {
  .ant-layout-header {
    display: flex;
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 54px;
    line-height: 48px;
    background-color: #FFFFFF;
  }

  .ant-dropdown-link {
    color: #191816;
    font-family: "MySegoeUI";
    font-size: medium;
    padding: 0 14px!important;
  }

  .ant-dropdown-link.ant-dropdown-open,
  .ant-dropdown-link:hover {
    color: #7a569e;
    background-color: #f7f8f8;
  }

  .sidebar {
    background-color: #440645;
    min-height: 1024px;
    height: 100vh;
    background-image: linear-gradient(to bottom, #440645 50%, White 50%);
    background-position: right;
    background-size: 8px 50px;
    background-repeat: repeat-y;
  }

  .warrior-img {
    position: absolute;
    bottom: 40px;
    left: 100px;
    width: 100%;
  }
  
  .desktop-menu {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 4px;
    margin-right: 20px;
    background: #FFF!important;
  }
  
  .mobile-menu {
    width: 100%;
    display: none;
    justify-content: flex-end;
    margin-top: 4px;
    margin-right: -26px;
    background: #FFF!important;
  }
  
  .ant-layout-footer {
    background-color: #1b1c1a;
    color: white;
  }

  @media only screen and (max-width: 640px) {
    .desktop-menu {
      display: none;
    }
  
    .mobile-menu {
      display: flex;
    }
  }
  
  @media only screen and (min-width: 1200px) {
    .logo {
      margin-left: 200px;
    }  
  }
}

.menu-wapper {
  .submenu-text {
    color: #191816;
    font-family: "MySegoeUI";
    font-size: medium;
    padding: 4px;
  }

  .ant-dropdown-menu-item-selected.submenu-item {
    color: #7a569e!important;
    background-color: #f7f8f8;
  }
}

.menu-wapper.ant-dropdown-menu {
  margin-top: -4px;
}