.profit-page {
  min-height: 100%;

  .content {
    background-color: #afa2cf;
  }

  .purple-bg {
    background-color: #8053a1;
    padding: 20px;
    /*background-image: linear-gradient(to bottom, #8053a1 50%, White 50%);
    background-position: left;
    background-size: 8px 50px;
    background-repeat: repeat-y;*/
  }

  .ant-space {
    width: 100%;
  }
  
  .main-wrapper {
    padding: 2%;
    margin-top: 10px;

    .row-item {
      width: 100%;
      text-align: left;
      padding: 0 14px 34px 14px;
    }

    .content-row {
      padding-left: 10%;
      padding-right: 10%;
    }

    .result-container {
      border-style: dashed;
      border-color: white;
      border-width: 8px;
      margin: 24px;
      min-height: 164px;
      position: relative;

      .total-profit {
        font-size: 18px;
        color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .profit-value {
          font-size: 36px;
          color: #f27315;
          font-weight: 600;
        }
      }
    }
  }

  .calc-button {
    background-color: #440645;
    color: white;
    border: 0;
    margin-top: 24px;
    height: 58px;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 2px;
    font-family: "MySegoeUI";
  }

  .calc-button:hover {
    background-color: #6a146b;
  }

  .bitcoin {
    display: flex;
    justify-content: right;

    .imgage {
      margin-right: 40px;
    }
  } 

  .item-title {
    color: white;
    font-size: 16px;
    margin-bottom: 8px;
  }

  @media only screen and (min-width: 1920px) {
    .content-row {
      padding-left: 14%;
      padding-right: 14%;
    }  
  }
}