.profitloss-page {
  min-height: 100%;

  .content {
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .background-wrapper {
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
  }

  .background-values {
    background-position: 0 center;
    background-repeat: no-repeat;
    background-size: auto;
  }


  .profitLossValue {
    font-size: 20px;
    font-weight: 800;
    color: #2fd83d;
  }

  .feeValue {
    font-size: 20px;
    font-weight: 800;
    color: #000000;
  }

  .descStr {
    font-size: 18px;
    font-weight: 500;
  }

  .inputNum{
    width: 100%;
  }

 

  .content-row {
    padding-left: 10%;
    padding-right: 10%;
  } 

  @media only screen and (max-width: 415px) {
    .background-wrapper{
      background-image: none !important;
    }

    .background-values{
      margin-top: 30px;
      margin-left: 10%;
      margin-right: 10%;
    }
  }

  @media only screen and (min-width: 1920px) {
    .content-row {
      padding-left: 14%;
      padding-right: 14%;
    }  
  }
}