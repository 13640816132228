.rotate {
  background-color: transparent;
  transform: rotate(90deg);
}

.cursor-pointer {
  cursor: pointer;
}

.header-title {
  margin-top: 3.0em;
  font-size: 32px;
  font-weight: 800;
}

.sub-title {
  font-size: 16px;
  line-height: 1.3;
  padding-left: 2%;
  padding-right: 2%;
}

.ant-input:focus,
.ant-input-number-focused,
.ant-input-affix-wrapper-focused,
.ant-select-focused {
  border-color: #78067a!important;
  box-shadow: 0 0 0 2px rgba(148, 63, 165, 0.2)!important;
}

.ant-input:hover,
.ant-input-number:hover,
.ant-input-affix-wrapper:hover {
  border-color: #78067a!important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #E3D3EF!important;;
}

.ant-select-focused {
  border-color: #78067a!important;
  box-shadow: 0 0 0 2px rgba(148, 63, 165, 0.2)!important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #E3D3EF!important;;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #78067a!important;
}

.ant-select-selector,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #78067a;
}

.ant-select-dropdown-menu-item-group-title {
  color: #666;
  font-weight: bold;
}

.ant-select-dropdown-menu-item-group {
  border-bottom: 1px solid #f6f6f6;
}

.ant-select-dropdown-menu-item {
  padding-left: 16px;
}

.ant-select-dropdown-menu-item.show-all {
  text-align: center;
  cursor: default;
}

.ant-select-dropdown-menu {
  max-height: 300px;
}

@media only screen and (max-width: 600px) {
  .header-title {
    font-size: 24px;
    padding: 0 20px;
    font-weight: 800;
  }

  .sub-title {
    padding: 0 20px;
    font-size: 16px;
  }
}