@font-face {
  font-family: "MySegoeUI";
  src: local("SegoeUI"), url("./fonts/Segoe UI.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "MySegoeUI";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.w-50 {
  width: 50%;
}
.w-100 {
  width: 100%;
}

.px-1 {
  padding: 0 1%;
}
.px-2 {
  padding: 0 2%;
}
.py-1 {
  padding: 1% 0;
}
.py-2 {
  padding: 2% 0;
}
.pt-1 {
  padding-top: 1%;
}
.pt-2 {
  padding-top: 2%;
}
.pt-3 {
  padding-top: 3%;
}
.mt-1 {
  margin-top: 1%;
}
.mt-2 {
  margin-top: 2%;
}
.mt-3 {
  margin-top: 3%;
}
.my-2 {
  margin: 2% 0;
}
.mb-1 {
  margin-bottom: 1%;
}
.mb-2 {
  margin-bottom: 2%;
}